import { default as indexntZab0lhDvMeta } from "/codebuild/output/src2043419230/src/src/pages/account/index.vue?macro=true";
import { default as _91announce_cd_93DRrrs9SfkQMeta } from "/codebuild/output/src2043419230/src/src/pages/announce/[announce_cd].vue?macro=true";
import { default as indexM1RfZXiu5pMeta } from "/codebuild/output/src2043419230/src/src/pages/att/index.vue?macro=true";
import { default as indexBOdVQJY04oMeta } from "/codebuild/output/src2043419230/src/src/pages/auth/index.vue?macro=true";
import { default as _91type_93_45clothesa8MJaedhg1Meta } from "/codebuild/output/src2043419230/src/src/pages/avatar/[type]-clothes.vue?macro=true";
import { default as closetAXWBa1pVveMeta } from "/codebuild/output/src2043419230/src/src/pages/avatar/closet.vue?macro=true";
import { default as indexMXcrUag621Meta } from "/codebuild/output/src2043419230/src/src/pages/avatar/index.vue?macro=true";
import { default as redecoratem6u7VKiadiMeta } from "/codebuild/output/src2043419230/src/src/pages/avatar/redecorate.vue?macro=true";
import { default as sellGuSE4wHMPwMeta } from "/codebuild/output/src2043419230/src/src/pages/avatar/sell.vue?macro=true";
import { default as deckaawVeVNJYdMeta } from "/codebuild/output/src2043419230/src/src/pages/card/deck.vue?macro=true";
import { default as indexZazRhb5dM0Meta } from "/codebuild/output/src2043419230/src/src/pages/card/index.vue?macro=true";
import { default as mypagePNI2D4H0uvMeta } from "/codebuild/output/src2043419230/src/src/pages/card/mypage.vue?macro=true";
import { default as select2ogXjALDI1Meta } from "/codebuild/output/src2043419230/src/src/pages/chara/select.vue?macro=true";
import { default as indexgNAHhKJ9cSMeta } from "/codebuild/output/src2043419230/src/src/pages/commu/[chara_cd]/index.vue?macro=true";
import { default as indexTzhG99wMBnMeta } from "/codebuild/output/src2043419230/src/src/pages/commu/index.vue?macro=true";
import { default as indexqfXrfrwf2aMeta } from "/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/bonus/index.vue?macro=true";
import { default as select1LWA8WKQMCMeta } from "/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/chara/select.vue?macro=true";
import { default as endJwoW3i7f1zMeta } from "/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/end.vue?macro=true";
import { default as indexufslC2gXG2Meta } from "/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/epilogue/index.vue?macro=true";
import { default as historyiHjBk9Vej2Meta } from "/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/minigame/history.vue?macro=true";
import { default as topcW9XCiXBZDMeta } from "/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/minigame/top.vue?macro=true";
import { default as mypagevPYVGJQLU0Meta } from "/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/mypage.vue?macro=true";
import { default as rankingSak46CukiuMeta } from "/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/ranking.vue?macro=true";
import { default as completex4LfpOJnMAMeta } from "/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/story/complete.vue?macro=true";
import { default as list71CQHI3X4AMeta } from "/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/story/list.vue?macro=true";
import { default as missionOhgyPiYCltMeta } from "/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/story/mission.vue?macro=true";
import { default as missionComplete0bcpcKJFmnMeta } from "/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/story/missionComplete.vue?macro=true";
import { default as topUzUJK7Le5bMeta } from "/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/top.vue?macro=true";
import { default as end4WKlXVZYAHMeta } from "/codebuild/output/src2043419230/src/src/pages/event/end.vue?macro=true";
import { default as followerlistWN1haexwGmMeta } from "/codebuild/output/src2043419230/src/src/pages/friend/followerlist.vue?macro=true";
import { default as followlistlnRmJ6p219Meta } from "/codebuild/output/src2043419230/src/src/pages/friend/followlist.vue?macro=true";
import { default as indexT1eiZbNheIMeta } from "/codebuild/output/src2043419230/src/src/pages/friend/index.vue?macro=true";
import { default as likeM74OnvqJktMeta } from "/codebuild/output/src2043419230/src/src/pages/friend/like.vue?macro=true";
import { default as messageboxhkgcam85xzMeta } from "/codebuild/output/src2043419230/src/src/pages/friend/messagebox.vue?macro=true";
import { default as search1twLXynm4XMeta } from "/codebuild/output/src2043419230/src/src/pages/friend/search.vue?macro=true";
import { default as indexGuJrvZ0ZEMMeta } from "/codebuild/output/src2043419230/src/src/pages/gacha/index.vue?macro=true";
import { default as _91type_93_45_91gachaCd_93Zy77QuELJjMeta } from "/codebuild/output/src2043419230/src/src/pages/gacha/lineup/[type]-[gachaCd].vue?macro=true";
import { default as result4xcMQqSOalMeta } from "/codebuild/output/src2043419230/src/src/pages/gacha/result.vue?macro=true";
import { default as captureYXsKriZvrqMeta } from "/codebuild/output/src2043419230/src/src/pages/his-collection/[chara_cd]/capture.vue?macro=true";
import { default as card6FRuAyieveMeta } from "/codebuild/output/src2043419230/src/src/pages/his-collection/[chara_cd]/card.vue?macro=true";
import { default as indexuUO7TImtVzMeta } from "/codebuild/output/src2043419230/src/src/pages/his-collection/[chara_cd]/index.vue?macro=true";
import { default as stillfr4QVe7B1JMeta } from "/codebuild/output/src2043419230/src/src/pages/his-collection/[chara_cd]/still.vue?macro=true";
import { default as storyAp9H8qFeCLMeta } from "/codebuild/output/src2043419230/src/src/pages/his-collection/[chara_cd]/story.vue?macro=true";
import { default as indexkrO0fjaextMeta } from "/codebuild/output/src2043419230/src/src/pages/his-collection/index.vue?macro=true";
import { default as completeKdNIE4D9xiMeta } from "/codebuild/output/src2043419230/src/src/pages/his-collection/story/complete.vue?macro=true";
import { default as indexBegnrkssAdMeta } from "/codebuild/output/src2043419230/src/src/pages/index.vue?macro=true";
import { default as listpbnO6am2oiMeta } from "/codebuild/output/src2043419230/src/src/pages/item/list.vue?macro=true";
import { default as historyeCym1izDYiMeta } from "/codebuild/output/src2043419230/src/src/pages/minigame/history.vue?macro=true";
import { default as rentalnUaO3AwiaeMeta } from "/codebuild/output/src2043419230/src/src/pages/minigame/rental.vue?macro=true";
import { default as topUM0fjJ2VenMeta } from "/codebuild/output/src2043419230/src/src/pages/minigame/top.vue?macro=true";
import { default as mypagen84I66QNs5Meta } from "/codebuild/output/src2043419230/src/src/pages/mypage.vue?macro=true";
import { default as no_45supportld03IoPfVsMeta } from "/codebuild/output/src2043419230/src/src/pages/no-support.vue?macro=true";
import { default as indexz94X5dEL0IMeta } from "/codebuild/output/src2043419230/src/src/pages/panelmission/index.vue?macro=true";
import { default as history_45_91type_930zsZ3hmXmcMeta } from "/codebuild/output/src2043419230/src/src/pages/present/history-[type].vue?macro=true";
import { default as listDhCdrfEva5Meta } from "/codebuild/output/src2043419230/src/src/pages/present/list.vue?macro=true";
import { default as friendUbd97gkyITMeta } from "/codebuild/output/src2043419230/src/src/pages/profile/friend.vue?macro=true";
import { default as indexuGCexuxLjLMeta } from "/codebuild/output/src2043419230/src/src/pages/profile/index.vue?macro=true";
import { default as _91combinedsale_cd_93WvwP1Rlg8EMeta } from "/codebuild/output/src2043419230/src/src/pages/scenario/[combinedsale_cd].vue?macro=true";
import { default as indexbLJvJKWvPFMeta } from "/codebuild/output/src2043419230/src/src/pages/serialcode/index.vue?macro=true";
import { default as account_45tradingvc3s5Ge8IXMeta } from "/codebuild/output/src2043419230/src/src/pages/setting/account-trading.vue?macro=true";
import { default as copyright_45ristrictj8YYDDZt3sMeta } from "/codebuild/output/src2043419230/src/src/pages/setting/copyright-ristrict.vue?macro=true";
import { default as copyrightVQviaRJOt5Meta } from "/codebuild/output/src2043419230/src/src/pages/setting/copyright.vue?macro=true";
import { default as creditssnjEPTAKsQMeta } from "/codebuild/output/src2043419230/src/src/pages/setting/credits.vue?macro=true";
import { default as helplAgQOLD8IxMeta } from "/codebuild/output/src2043419230/src/src/pages/setting/help.vue?macro=true";
import { default as licensemjANS5QvanMeta } from "/codebuild/output/src2043419230/src/src/pages/setting/license.vue?macro=true";
import { default as menuL0QYl8ZTKsMeta } from "/codebuild/output/src2043419230/src/src/pages/setting/menu.vue?macro=true";
import { default as payment_45service_45act2DcpItKcsRMeta } from "/codebuild/output/src2043419230/src/src/pages/setting/payment-service-act.vue?macro=true";
import { default as term_45of_45serviceD9Eg4AYABNMeta } from "/codebuild/output/src2043419230/src/src/pages/setting/term-of-service.vue?macro=true";
import { default as diazrvHXoDJSoMeta } from "/codebuild/output/src2043419230/src/src/pages/shop/dia.vue?macro=true";
import { default as index5IVVapqrVfMeta } from "/codebuild/output/src2043419230/src/src/pages/shop/index.vue?macro=true";
import { default as itemAwnqwupdRWMeta } from "/codebuild/output/src2043419230/src/src/pages/shop/item.vue?macro=true";
import { default as set8udKuoQeJfMeta } from "/codebuild/output/src2043419230/src/src/pages/shop/set.vue?macro=true";
import { default as complete13WS3l5qBdMeta } from "/codebuild/output/src2043419230/src/src/pages/story/complete.vue?macro=true";
import { default as item_45_91type_93P3P9sqgzFcMeta } from "/codebuild/output/src2043419230/src/src/pages/story/item-[type].vue?macro=true";
import { default as listDdG0nJIThwMeta } from "/codebuild/output/src2043419230/src/src/pages/story/list.vue?macro=true";
import { default as missionELx9htIz6iMeta } from "/codebuild/output/src2043419230/src/src/pages/story/mission.vue?macro=true";
import { default as missionCompleteeCIAsl9ziMMeta } from "/codebuild/output/src2043419230/src/src/pages/story/missionComplete.vue?macro=true";
import { default as playd3xJC3MlT5Meta } from "/codebuild/output/src2043419230/src/src/pages/story/play.vue?macro=true";
import { default as via1U5ja6vZBpMeta } from "/codebuild/output/src2043419230/src/src/pages/story/via.vue?macro=true";
import { default as index0ZX8bd93YSMeta } from "/codebuild/output/src2043419230/src/src/pages/terms-of-service/index.vue?macro=true";
import { default as titlermSEHSSvM1Meta } from "/codebuild/output/src2043419230/src/src/pages/title.vue?macro=true";
import { default as nameEI0CJskJxEMeta } from "/codebuild/output/src2043419230/src/src/pages/tutorial/name.vue?macro=true";
import { default as prologueHIyEpG54DNMeta } from "/codebuild/output/src2043419230/src/src/pages/tutorial/prologue.vue?macro=true";
import { default as updateBu1ShxyqbmMeta } from "/codebuild/output/src2043419230/src/src/pages/update.vue?macro=true";
import { default as version_45upq5N0AznaKHMeta } from "/codebuild/output/src2043419230/src/src/pages/version-up.vue?macro=true";
import { default as indexbsCq9v3ztaMeta } from "/codebuild/output/src2043419230/src/src/node_modules/nuxt-debug/dist/runtime/pages/index.vue?macro=true";
import { default as cache40aZMkQz6MMeta } from "/codebuild/output/src2043419230/src/src/node_modules/nuxt-debug/dist/runtime/pages/cache.vue?macro=true";
import { default as logkf7n8ZflV9Meta } from "/codebuild/output/src2043419230/src/src/node_modules/nuxt-debug/dist/runtime/pages/log.vue?macro=true";
import { default as animateu1nJRgqDzeMeta } from "/codebuild/output/src2043419230/src/src/debug/animate.vue?macro=true";
import { default as audioYlwbnfbbiWMeta } from "/codebuild/output/src2043419230/src/src/debug/audio.vue?macro=true";
import { default as authYfcLfAc5rdMeta } from "/codebuild/output/src2043419230/src/src/debug/auth.vue?macro=true";
import { default as debugButtonlshiXBoFIYMeta } from "/codebuild/output/src2043419230/src/src/debug/debugButton.vue?macro=true";
import { default as directionWUtvwy2odhMeta } from "/codebuild/output/src2043419230/src/src/debug/direction.vue?macro=true";
import { default as environmentKV6VGL7GYDMeta } from "/codebuild/output/src2043419230/src/src/debug/environment.vue?macro=true";
import { default as lottiedncoaco4e5Meta } from "/codebuild/output/src2043419230/src/src/debug/lottie.vue?macro=true";
import { default as movieFEcgcdcaUGMeta } from "/codebuild/output/src2043419230/src/src/debug/movie.vue?macro=true";
import { default as playerPrefsga7Xv3L8s4Meta } from "/codebuild/output/src2043419230/src/src/debug/playerPrefs.vue?macro=true";
import { default as pushBPQ4mabeIxMeta } from "/codebuild/output/src2043419230/src/src/debug/push.vue?macro=true";
import { default as screendUlRQSRWSKMeta } from "/codebuild/output/src2043419230/src/src/debug/screen.vue?macro=true";
import { default as server8MIUzJ6VYBMeta } from "/codebuild/output/src2043419230/src/src/debug/server.vue?macro=true";
import { default as storagevASI2Ah67IMeta } from "/codebuild/output/src2043419230/src/src/debug/storage.vue?macro=true";
import { default as uih8fRcJo6WSMeta } from "/codebuild/output/src2043419230/src/src/debug/ui.vue?macro=true";
export default [
  {
    name: indexntZab0lhDvMeta?.name ?? "account",
    path: indexntZab0lhDvMeta?.path ?? "/account",
    meta: indexntZab0lhDvMeta || {},
    alias: indexntZab0lhDvMeta?.alias || [],
    redirect: indexntZab0lhDvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91announce_cd_93DRrrs9SfkQMeta?.name ?? "announce-announce_cd",
    path: _91announce_cd_93DRrrs9SfkQMeta?.path ?? "/announce/:announce_cd()",
    meta: _91announce_cd_93DRrrs9SfkQMeta || {},
    alias: _91announce_cd_93DRrrs9SfkQMeta?.alias || [],
    redirect: _91announce_cd_93DRrrs9SfkQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/announce/[announce_cd].vue").then(m => m.default || m)
  },
  {
    name: indexM1RfZXiu5pMeta?.name ?? "att",
    path: indexM1RfZXiu5pMeta?.path ?? "/att",
    meta: indexM1RfZXiu5pMeta || {},
    alias: indexM1RfZXiu5pMeta?.alias || [],
    redirect: indexM1RfZXiu5pMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/att/index.vue").then(m => m.default || m)
  },
  {
    name: indexBOdVQJY04oMeta?.name ?? "auth",
    path: indexBOdVQJY04oMeta?.path ?? "/auth",
    meta: indexBOdVQJY04oMeta || {},
    alias: indexBOdVQJY04oMeta?.alias || [],
    redirect: indexBOdVQJY04oMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93_45clothesa8MJaedhg1Meta?.name ?? "avatar-type-clothes",
    path: _91type_93_45clothesa8MJaedhg1Meta?.path ?? "/avatar/:type()-clothes",
    meta: _91type_93_45clothesa8MJaedhg1Meta || {},
    alias: _91type_93_45clothesa8MJaedhg1Meta?.alias || [],
    redirect: _91type_93_45clothesa8MJaedhg1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/avatar/[type]-clothes.vue").then(m => m.default || m)
  },
  {
    name: closetAXWBa1pVveMeta?.name ?? "avatar-closet",
    path: closetAXWBa1pVveMeta?.path ?? "/avatar/closet",
    meta: closetAXWBa1pVveMeta || {},
    alias: closetAXWBa1pVveMeta?.alias || [],
    redirect: closetAXWBa1pVveMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/avatar/closet.vue").then(m => m.default || m)
  },
  {
    name: indexMXcrUag621Meta?.name ?? "avatar",
    path: indexMXcrUag621Meta?.path ?? "/avatar",
    meta: indexMXcrUag621Meta || {},
    alias: indexMXcrUag621Meta?.alias || [],
    redirect: indexMXcrUag621Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/avatar/index.vue").then(m => m.default || m)
  },
  {
    name: redecoratem6u7VKiadiMeta?.name ?? "avatar-redecorate",
    path: redecoratem6u7VKiadiMeta?.path ?? "/avatar/redecorate",
    meta: redecoratem6u7VKiadiMeta || {},
    alias: redecoratem6u7VKiadiMeta?.alias || [],
    redirect: redecoratem6u7VKiadiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/avatar/redecorate.vue").then(m => m.default || m)
  },
  {
    name: sellGuSE4wHMPwMeta?.name ?? "avatar-sell",
    path: sellGuSE4wHMPwMeta?.path ?? "/avatar/sell",
    meta: sellGuSE4wHMPwMeta || {},
    alias: sellGuSE4wHMPwMeta?.alias || [],
    redirect: sellGuSE4wHMPwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/avatar/sell.vue").then(m => m.default || m)
  },
  {
    name: deckaawVeVNJYdMeta?.name ?? "card-deck",
    path: deckaawVeVNJYdMeta?.path ?? "/card/deck",
    meta: deckaawVeVNJYdMeta || {},
    alias: deckaawVeVNJYdMeta?.alias || [],
    redirect: deckaawVeVNJYdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/card/deck.vue").then(m => m.default || m)
  },
  {
    name: indexZazRhb5dM0Meta?.name ?? "card",
    path: indexZazRhb5dM0Meta?.path ?? "/card",
    meta: indexZazRhb5dM0Meta || {},
    alias: indexZazRhb5dM0Meta?.alias || [],
    redirect: indexZazRhb5dM0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/card/index.vue").then(m => m.default || m)
  },
  {
    name: mypagePNI2D4H0uvMeta?.name ?? "card-mypage",
    path: mypagePNI2D4H0uvMeta?.path ?? "/card/mypage",
    meta: mypagePNI2D4H0uvMeta || {},
    alias: mypagePNI2D4H0uvMeta?.alias || [],
    redirect: mypagePNI2D4H0uvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/card/mypage.vue").then(m => m.default || m)
  },
  {
    name: select2ogXjALDI1Meta?.name ?? "chara-select",
    path: select2ogXjALDI1Meta?.path ?? "/chara/select",
    meta: select2ogXjALDI1Meta || {},
    alias: select2ogXjALDI1Meta?.alias || [],
    redirect: select2ogXjALDI1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/chara/select.vue").then(m => m.default || m)
  },
  {
    name: indexgNAHhKJ9cSMeta?.name ?? "commu-chara_cd",
    path: indexgNAHhKJ9cSMeta?.path ?? "/commu/:chara_cd()",
    meta: indexgNAHhKJ9cSMeta || {},
    alias: indexgNAHhKJ9cSMeta?.alias || [],
    redirect: indexgNAHhKJ9cSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/commu/[chara_cd]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzhG99wMBnMeta?.name ?? "commu",
    path: indexTzhG99wMBnMeta?.path ?? "/commu",
    meta: indexTzhG99wMBnMeta || {},
    alias: indexTzhG99wMBnMeta?.alias || [],
    redirect: indexTzhG99wMBnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/commu/index.vue").then(m => m.default || m)
  },
  {
    name: indexqfXrfrwf2aMeta?.name ?? "event-event_cd-bonus",
    path: indexqfXrfrwf2aMeta?.path ?? "/event/:event_cd()/bonus",
    meta: indexqfXrfrwf2aMeta || {},
    alias: indexqfXrfrwf2aMeta?.alias || [],
    redirect: indexqfXrfrwf2aMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/bonus/index.vue").then(m => m.default || m)
  },
  {
    name: select1LWA8WKQMCMeta?.name ?? "event-event_cd-chara-select",
    path: select1LWA8WKQMCMeta?.path ?? "/event/:event_cd()/chara/select",
    meta: select1LWA8WKQMCMeta || {},
    alias: select1LWA8WKQMCMeta?.alias || [],
    redirect: select1LWA8WKQMCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/chara/select.vue").then(m => m.default || m)
  },
  {
    name: endJwoW3i7f1zMeta?.name ?? "event-event_cd-end",
    path: endJwoW3i7f1zMeta?.path ?? "/event/:event_cd()/end",
    meta: endJwoW3i7f1zMeta || {},
    alias: endJwoW3i7f1zMeta?.alias || [],
    redirect: endJwoW3i7f1zMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/end.vue").then(m => m.default || m)
  },
  {
    name: indexufslC2gXG2Meta?.name ?? "event-event_cd-epilogue",
    path: indexufslC2gXG2Meta?.path ?? "/event/:event_cd()/epilogue",
    meta: indexufslC2gXG2Meta || {},
    alias: indexufslC2gXG2Meta?.alias || [],
    redirect: indexufslC2gXG2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/epilogue/index.vue").then(m => m.default || m)
  },
  {
    name: historyiHjBk9Vej2Meta?.name ?? "event-event_cd-minigame-history",
    path: historyiHjBk9Vej2Meta?.path ?? "/event/:event_cd()/minigame/history",
    meta: historyiHjBk9Vej2Meta || {},
    alias: historyiHjBk9Vej2Meta?.alias || [],
    redirect: historyiHjBk9Vej2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/minigame/history.vue").then(m => m.default || m)
  },
  {
    name: topcW9XCiXBZDMeta?.name ?? "event-event_cd-minigame-top",
    path: topcW9XCiXBZDMeta?.path ?? "/event/:event_cd()/minigame/top",
    meta: topcW9XCiXBZDMeta || {},
    alias: topcW9XCiXBZDMeta?.alias || [],
    redirect: topcW9XCiXBZDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/minigame/top.vue").then(m => m.default || m)
  },
  {
    name: mypagevPYVGJQLU0Meta?.name ?? "event-event_cd-mypage",
    path: mypagevPYVGJQLU0Meta?.path ?? "/event/:event_cd()/mypage",
    meta: mypagevPYVGJQLU0Meta || {},
    alias: mypagevPYVGJQLU0Meta?.alias || [],
    redirect: mypagevPYVGJQLU0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/mypage.vue").then(m => m.default || m)
  },
  {
    name: rankingSak46CukiuMeta?.name ?? "event-event_cd-ranking",
    path: rankingSak46CukiuMeta?.path ?? "/event/:event_cd()/ranking",
    meta: rankingSak46CukiuMeta || {},
    alias: rankingSak46CukiuMeta?.alias || [],
    redirect: rankingSak46CukiuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/ranking.vue").then(m => m.default || m)
  },
  {
    name: completex4LfpOJnMAMeta?.name ?? "event-event_cd-story-complete",
    path: completex4LfpOJnMAMeta?.path ?? "/event/:event_cd()/story/complete",
    meta: completex4LfpOJnMAMeta || {},
    alias: completex4LfpOJnMAMeta?.alias || [],
    redirect: completex4LfpOJnMAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/story/complete.vue").then(m => m.default || m)
  },
  {
    name: list71CQHI3X4AMeta?.name ?? "event-event_cd-story-list",
    path: list71CQHI3X4AMeta?.path ?? "/event/:event_cd()/story/list",
    meta: list71CQHI3X4AMeta || {},
    alias: list71CQHI3X4AMeta?.alias || [],
    redirect: list71CQHI3X4AMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/story/list.vue").then(m => m.default || m)
  },
  {
    name: missionOhgyPiYCltMeta?.name ?? "event-event_cd-story-mission",
    path: missionOhgyPiYCltMeta?.path ?? "/event/:event_cd()/story/mission",
    meta: missionOhgyPiYCltMeta || {},
    alias: missionOhgyPiYCltMeta?.alias || [],
    redirect: missionOhgyPiYCltMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/story/mission.vue").then(m => m.default || m)
  },
  {
    name: missionComplete0bcpcKJFmnMeta?.name ?? "event-event_cd-story-missionComplete",
    path: missionComplete0bcpcKJFmnMeta?.path ?? "/event/:event_cd()/story/missionComplete",
    meta: missionComplete0bcpcKJFmnMeta || {},
    alias: missionComplete0bcpcKJFmnMeta?.alias || [],
    redirect: missionComplete0bcpcKJFmnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/story/missionComplete.vue").then(m => m.default || m)
  },
  {
    name: topUzUJK7Le5bMeta?.name ?? "event-event_cd-top",
    path: topUzUJK7Le5bMeta?.path ?? "/event/:event_cd()/top",
    meta: topUzUJK7Le5bMeta || {},
    alias: topUzUJK7Le5bMeta?.alias || [],
    redirect: topUzUJK7Le5bMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/event/[event_cd]/top.vue").then(m => m.default || m)
  },
  {
    name: end4WKlXVZYAHMeta?.name ?? "event-end",
    path: end4WKlXVZYAHMeta?.path ?? "/event/end",
    meta: end4WKlXVZYAHMeta || {},
    alias: end4WKlXVZYAHMeta?.alias || [],
    redirect: end4WKlXVZYAHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/event/end.vue").then(m => m.default || m)
  },
  {
    name: followerlistWN1haexwGmMeta?.name ?? "friend-followerlist",
    path: followerlistWN1haexwGmMeta?.path ?? "/friend/followerlist",
    meta: followerlistWN1haexwGmMeta || {},
    alias: followerlistWN1haexwGmMeta?.alias || [],
    redirect: followerlistWN1haexwGmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/friend/followerlist.vue").then(m => m.default || m)
  },
  {
    name: followlistlnRmJ6p219Meta?.name ?? "friend-followlist",
    path: followlistlnRmJ6p219Meta?.path ?? "/friend/followlist",
    meta: followlistlnRmJ6p219Meta || {},
    alias: followlistlnRmJ6p219Meta?.alias || [],
    redirect: followlistlnRmJ6p219Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/friend/followlist.vue").then(m => m.default || m)
  },
  {
    name: indexT1eiZbNheIMeta?.name ?? "friend",
    path: indexT1eiZbNheIMeta?.path ?? "/friend",
    meta: indexT1eiZbNheIMeta || {},
    alias: indexT1eiZbNheIMeta?.alias || [],
    redirect: indexT1eiZbNheIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/friend/index.vue").then(m => m.default || m)
  },
  {
    name: likeM74OnvqJktMeta?.name ?? "friend-like",
    path: likeM74OnvqJktMeta?.path ?? "/friend/like",
    meta: likeM74OnvqJktMeta || {},
    alias: likeM74OnvqJktMeta?.alias || [],
    redirect: likeM74OnvqJktMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/friend/like.vue").then(m => m.default || m)
  },
  {
    name: messageboxhkgcam85xzMeta?.name ?? "friend-messagebox",
    path: messageboxhkgcam85xzMeta?.path ?? "/friend/messagebox",
    meta: messageboxhkgcam85xzMeta || {},
    alias: messageboxhkgcam85xzMeta?.alias || [],
    redirect: messageboxhkgcam85xzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/friend/messagebox.vue").then(m => m.default || m)
  },
  {
    name: search1twLXynm4XMeta?.name ?? "friend-search",
    path: search1twLXynm4XMeta?.path ?? "/friend/search",
    meta: search1twLXynm4XMeta || {},
    alias: search1twLXynm4XMeta?.alias || [],
    redirect: search1twLXynm4XMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/friend/search.vue").then(m => m.default || m)
  },
  {
    name: indexGuJrvZ0ZEMMeta?.name ?? "gacha",
    path: indexGuJrvZ0ZEMMeta?.path ?? "/gacha",
    meta: indexGuJrvZ0ZEMMeta || {},
    alias: indexGuJrvZ0ZEMMeta?.alias || [],
    redirect: indexGuJrvZ0ZEMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/gacha/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93_45_91gachaCd_93Zy77QuELJjMeta?.name ?? "gacha-lineup-type-gachaCd",
    path: _91type_93_45_91gachaCd_93Zy77QuELJjMeta?.path ?? "/gacha/lineup/:type()-:gachaCd()",
    meta: _91type_93_45_91gachaCd_93Zy77QuELJjMeta || {},
    alias: _91type_93_45_91gachaCd_93Zy77QuELJjMeta?.alias || [],
    redirect: _91type_93_45_91gachaCd_93Zy77QuELJjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/gacha/lineup/[type]-[gachaCd].vue").then(m => m.default || m)
  },
  {
    name: result4xcMQqSOalMeta?.name ?? "gacha-result",
    path: result4xcMQqSOalMeta?.path ?? "/gacha/result",
    meta: result4xcMQqSOalMeta || {},
    alias: result4xcMQqSOalMeta?.alias || [],
    redirect: result4xcMQqSOalMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/gacha/result.vue").then(m => m.default || m)
  },
  {
    name: captureYXsKriZvrqMeta?.name ?? "his-collection-chara_cd-capture",
    path: captureYXsKriZvrqMeta?.path ?? "/his-collection/:chara_cd()/capture",
    meta: captureYXsKriZvrqMeta || {},
    alias: captureYXsKriZvrqMeta?.alias || [],
    redirect: captureYXsKriZvrqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/his-collection/[chara_cd]/capture.vue").then(m => m.default || m)
  },
  {
    name: card6FRuAyieveMeta?.name ?? "his-collection-chara_cd-card",
    path: card6FRuAyieveMeta?.path ?? "/his-collection/:chara_cd()/card",
    meta: card6FRuAyieveMeta || {},
    alias: card6FRuAyieveMeta?.alias || [],
    redirect: card6FRuAyieveMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/his-collection/[chara_cd]/card.vue").then(m => m.default || m)
  },
  {
    name: indexuUO7TImtVzMeta?.name ?? "his-collection-chara_cd",
    path: indexuUO7TImtVzMeta?.path ?? "/his-collection/:chara_cd()",
    meta: indexuUO7TImtVzMeta || {},
    alias: indexuUO7TImtVzMeta?.alias || [],
    redirect: indexuUO7TImtVzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/his-collection/[chara_cd]/index.vue").then(m => m.default || m)
  },
  {
    name: stillfr4QVe7B1JMeta?.name ?? "his-collection-chara_cd-still",
    path: stillfr4QVe7B1JMeta?.path ?? "/his-collection/:chara_cd()/still",
    meta: stillfr4QVe7B1JMeta || {},
    alias: stillfr4QVe7B1JMeta?.alias || [],
    redirect: stillfr4QVe7B1JMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/his-collection/[chara_cd]/still.vue").then(m => m.default || m)
  },
  {
    name: storyAp9H8qFeCLMeta?.name ?? "his-collection-chara_cd-story",
    path: storyAp9H8qFeCLMeta?.path ?? "/his-collection/:chara_cd()/story",
    meta: storyAp9H8qFeCLMeta || {},
    alias: storyAp9H8qFeCLMeta?.alias || [],
    redirect: storyAp9H8qFeCLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/his-collection/[chara_cd]/story.vue").then(m => m.default || m)
  },
  {
    name: indexkrO0fjaextMeta?.name ?? "his-collection",
    path: indexkrO0fjaextMeta?.path ?? "/his-collection",
    meta: indexkrO0fjaextMeta || {},
    alias: indexkrO0fjaextMeta?.alias || [],
    redirect: indexkrO0fjaextMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/his-collection/index.vue").then(m => m.default || m)
  },
  {
    name: completeKdNIE4D9xiMeta?.name ?? "his-collection-story-complete",
    path: completeKdNIE4D9xiMeta?.path ?? "/his-collection/story/complete",
    meta: completeKdNIE4D9xiMeta || {},
    alias: completeKdNIE4D9xiMeta?.alias || [],
    redirect: completeKdNIE4D9xiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/his-collection/story/complete.vue").then(m => m.default || m)
  },
  {
    name: indexBegnrkssAdMeta?.name ?? "index",
    path: indexBegnrkssAdMeta?.path ?? "/",
    meta: indexBegnrkssAdMeta || {},
    alias: indexBegnrkssAdMeta?.alias || [],
    redirect: indexBegnrkssAdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: listpbnO6am2oiMeta?.name ?? "item-list",
    path: listpbnO6am2oiMeta?.path ?? "/item/list",
    meta: listpbnO6am2oiMeta || {},
    alias: listpbnO6am2oiMeta?.alias || [],
    redirect: listpbnO6am2oiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/item/list.vue").then(m => m.default || m)
  },
  {
    name: historyeCym1izDYiMeta?.name ?? "minigame-history",
    path: historyeCym1izDYiMeta?.path ?? "/minigame/history",
    meta: historyeCym1izDYiMeta || {},
    alias: historyeCym1izDYiMeta?.alias || [],
    redirect: historyeCym1izDYiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/minigame/history.vue").then(m => m.default || m)
  },
  {
    name: rentalnUaO3AwiaeMeta?.name ?? "minigame-rental",
    path: rentalnUaO3AwiaeMeta?.path ?? "/minigame/rental",
    meta: rentalnUaO3AwiaeMeta || {},
    alias: rentalnUaO3AwiaeMeta?.alias || [],
    redirect: rentalnUaO3AwiaeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/minigame/rental.vue").then(m => m.default || m)
  },
  {
    name: topUM0fjJ2VenMeta?.name ?? "minigame-top",
    path: topUM0fjJ2VenMeta?.path ?? "/minigame/top",
    meta: topUM0fjJ2VenMeta || {},
    alias: topUM0fjJ2VenMeta?.alias || [],
    redirect: topUM0fjJ2VenMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/minigame/top.vue").then(m => m.default || m)
  },
  {
    name: mypagen84I66QNs5Meta?.name ?? "mypage",
    path: mypagen84I66QNs5Meta?.path ?? "/mypage",
    meta: mypagen84I66QNs5Meta || {},
    alias: mypagen84I66QNs5Meta?.alias || [],
    redirect: mypagen84I66QNs5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/mypage.vue").then(m => m.default || m)
  },
  {
    name: no_45supportld03IoPfVsMeta?.name ?? "no-support",
    path: no_45supportld03IoPfVsMeta?.path ?? "/no-support",
    meta: no_45supportld03IoPfVsMeta || {},
    alias: no_45supportld03IoPfVsMeta?.alias || [],
    redirect: no_45supportld03IoPfVsMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/no-support.vue").then(m => m.default || m)
  },
  {
    name: indexz94X5dEL0IMeta?.name ?? "panelmission",
    path: indexz94X5dEL0IMeta?.path ?? "/panelmission",
    meta: indexz94X5dEL0IMeta || {},
    alias: indexz94X5dEL0IMeta?.alias || [],
    redirect: indexz94X5dEL0IMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/panelmission/index.vue").then(m => m.default || m)
  },
  {
    name: history_45_91type_930zsZ3hmXmcMeta?.name ?? "present-history-type",
    path: history_45_91type_930zsZ3hmXmcMeta?.path ?? "/present/history-:type()",
    meta: history_45_91type_930zsZ3hmXmcMeta || {},
    alias: history_45_91type_930zsZ3hmXmcMeta?.alias || [],
    redirect: history_45_91type_930zsZ3hmXmcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/present/history-[type].vue").then(m => m.default || m)
  },
  {
    name: listDhCdrfEva5Meta?.name ?? "present-list",
    path: listDhCdrfEva5Meta?.path ?? "/present/list",
    meta: listDhCdrfEva5Meta || {},
    alias: listDhCdrfEva5Meta?.alias || [],
    redirect: listDhCdrfEva5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/present/list.vue").then(m => m.default || m)
  },
  {
    name: friendUbd97gkyITMeta?.name ?? "profile-friend",
    path: friendUbd97gkyITMeta?.path ?? "/profile/friend",
    meta: friendUbd97gkyITMeta || {},
    alias: friendUbd97gkyITMeta?.alias || [],
    redirect: friendUbd97gkyITMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/profile/friend.vue").then(m => m.default || m)
  },
  {
    name: indexuGCexuxLjLMeta?.name ?? "profile",
    path: indexuGCexuxLjLMeta?.path ?? "/profile",
    meta: indexuGCexuxLjLMeta || {},
    alias: indexuGCexuxLjLMeta?.alias || [],
    redirect: indexuGCexuxLjLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: _91combinedsale_cd_93WvwP1Rlg8EMeta?.name ?? "scenario-combinedsale_cd",
    path: _91combinedsale_cd_93WvwP1Rlg8EMeta?.path ?? "/scenario/:combinedsale_cd()",
    meta: _91combinedsale_cd_93WvwP1Rlg8EMeta || {},
    alias: _91combinedsale_cd_93WvwP1Rlg8EMeta?.alias || [],
    redirect: _91combinedsale_cd_93WvwP1Rlg8EMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/scenario/[combinedsale_cd].vue").then(m => m.default || m)
  },
  {
    name: indexbLJvJKWvPFMeta?.name ?? "serialcode",
    path: indexbLJvJKWvPFMeta?.path ?? "/serialcode",
    meta: indexbLJvJKWvPFMeta || {},
    alias: indexbLJvJKWvPFMeta?.alias || [],
    redirect: indexbLJvJKWvPFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/serialcode/index.vue").then(m => m.default || m)
  },
  {
    name: account_45tradingvc3s5Ge8IXMeta?.name ?? "setting-account-trading",
    path: account_45tradingvc3s5Ge8IXMeta?.path ?? "/setting/account-trading",
    meta: account_45tradingvc3s5Ge8IXMeta || {},
    alias: account_45tradingvc3s5Ge8IXMeta?.alias || [],
    redirect: account_45tradingvc3s5Ge8IXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/setting/account-trading.vue").then(m => m.default || m)
  },
  {
    name: copyright_45ristrictj8YYDDZt3sMeta?.name ?? "setting-copyright-ristrict",
    path: copyright_45ristrictj8YYDDZt3sMeta?.path ?? "/setting/copyright-ristrict",
    meta: copyright_45ristrictj8YYDDZt3sMeta || {},
    alias: copyright_45ristrictj8YYDDZt3sMeta?.alias || [],
    redirect: copyright_45ristrictj8YYDDZt3sMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/setting/copyright-ristrict.vue").then(m => m.default || m)
  },
  {
    name: copyrightVQviaRJOt5Meta?.name ?? "setting-copyright",
    path: copyrightVQviaRJOt5Meta?.path ?? "/setting/copyright",
    meta: copyrightVQviaRJOt5Meta || {},
    alias: copyrightVQviaRJOt5Meta?.alias || [],
    redirect: copyrightVQviaRJOt5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/setting/copyright.vue").then(m => m.default || m)
  },
  {
    name: creditssnjEPTAKsQMeta?.name ?? "setting-credits",
    path: creditssnjEPTAKsQMeta?.path ?? "/setting/credits",
    meta: creditssnjEPTAKsQMeta || {},
    alias: creditssnjEPTAKsQMeta?.alias || [],
    redirect: creditssnjEPTAKsQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/setting/credits.vue").then(m => m.default || m)
  },
  {
    name: helplAgQOLD8IxMeta?.name ?? "setting-help",
    path: helplAgQOLD8IxMeta?.path ?? "/setting/help",
    meta: helplAgQOLD8IxMeta || {},
    alias: helplAgQOLD8IxMeta?.alias || [],
    redirect: helplAgQOLD8IxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/setting/help.vue").then(m => m.default || m)
  },
  {
    name: licensemjANS5QvanMeta?.name ?? "setting-license",
    path: licensemjANS5QvanMeta?.path ?? "/setting/license",
    meta: licensemjANS5QvanMeta || {},
    alias: licensemjANS5QvanMeta?.alias || [],
    redirect: licensemjANS5QvanMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/setting/license.vue").then(m => m.default || m)
  },
  {
    name: menuL0QYl8ZTKsMeta?.name ?? "setting-menu",
    path: menuL0QYl8ZTKsMeta?.path ?? "/setting/menu",
    meta: menuL0QYl8ZTKsMeta || {},
    alias: menuL0QYl8ZTKsMeta?.alias || [],
    redirect: menuL0QYl8ZTKsMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/setting/menu.vue").then(m => m.default || m)
  },
  {
    name: payment_45service_45act2DcpItKcsRMeta?.name ?? "setting-payment-service-act",
    path: payment_45service_45act2DcpItKcsRMeta?.path ?? "/setting/payment-service-act",
    meta: payment_45service_45act2DcpItKcsRMeta || {},
    alias: payment_45service_45act2DcpItKcsRMeta?.alias || [],
    redirect: payment_45service_45act2DcpItKcsRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/setting/payment-service-act.vue").then(m => m.default || m)
  },
  {
    name: term_45of_45serviceD9Eg4AYABNMeta?.name ?? "setting-term-of-service",
    path: term_45of_45serviceD9Eg4AYABNMeta?.path ?? "/setting/term-of-service",
    meta: term_45of_45serviceD9Eg4AYABNMeta || {},
    alias: term_45of_45serviceD9Eg4AYABNMeta?.alias || [],
    redirect: term_45of_45serviceD9Eg4AYABNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/setting/term-of-service.vue").then(m => m.default || m)
  },
  {
    name: diazrvHXoDJSoMeta?.name ?? "shop-dia",
    path: diazrvHXoDJSoMeta?.path ?? "/shop/dia",
    meta: diazrvHXoDJSoMeta || {},
    alias: diazrvHXoDJSoMeta?.alias || [],
    redirect: diazrvHXoDJSoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/shop/dia.vue").then(m => m.default || m)
  },
  {
    name: index5IVVapqrVfMeta?.name ?? "shop",
    path: index5IVVapqrVfMeta?.path ?? "/shop",
    meta: index5IVVapqrVfMeta || {},
    alias: index5IVVapqrVfMeta?.alias || [],
    redirect: index5IVVapqrVfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: itemAwnqwupdRWMeta?.name ?? "shop-item",
    path: itemAwnqwupdRWMeta?.path ?? "/shop/item",
    meta: itemAwnqwupdRWMeta || {},
    alias: itemAwnqwupdRWMeta?.alias || [],
    redirect: itemAwnqwupdRWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/shop/item.vue").then(m => m.default || m)
  },
  {
    name: set8udKuoQeJfMeta?.name ?? "shop-set",
    path: set8udKuoQeJfMeta?.path ?? "/shop/set",
    meta: set8udKuoQeJfMeta || {},
    alias: set8udKuoQeJfMeta?.alias || [],
    redirect: set8udKuoQeJfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/shop/set.vue").then(m => m.default || m)
  },
  {
    name: complete13WS3l5qBdMeta?.name ?? "story-complete",
    path: complete13WS3l5qBdMeta?.path ?? "/story/complete",
    meta: complete13WS3l5qBdMeta || {},
    alias: complete13WS3l5qBdMeta?.alias || [],
    redirect: complete13WS3l5qBdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/story/complete.vue").then(m => m.default || m)
  },
  {
    name: item_45_91type_93P3P9sqgzFcMeta?.name ?? "story-item-type",
    path: item_45_91type_93P3P9sqgzFcMeta?.path ?? "/story/item-:type()",
    meta: item_45_91type_93P3P9sqgzFcMeta || {},
    alias: item_45_91type_93P3P9sqgzFcMeta?.alias || [],
    redirect: item_45_91type_93P3P9sqgzFcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/story/item-[type].vue").then(m => m.default || m)
  },
  {
    name: listDdG0nJIThwMeta?.name ?? "story-list",
    path: listDdG0nJIThwMeta?.path ?? "/story/list",
    meta: listDdG0nJIThwMeta || {},
    alias: listDdG0nJIThwMeta?.alias || [],
    redirect: listDdG0nJIThwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/story/list.vue").then(m => m.default || m)
  },
  {
    name: missionELx9htIz6iMeta?.name ?? "story-mission",
    path: missionELx9htIz6iMeta?.path ?? "/story/mission",
    meta: missionELx9htIz6iMeta || {},
    alias: missionELx9htIz6iMeta?.alias || [],
    redirect: missionELx9htIz6iMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/story/mission.vue").then(m => m.default || m)
  },
  {
    name: missionCompleteeCIAsl9ziMMeta?.name ?? "story-missionComplete",
    path: missionCompleteeCIAsl9ziMMeta?.path ?? "/story/missionComplete",
    meta: missionCompleteeCIAsl9ziMMeta || {},
    alias: missionCompleteeCIAsl9ziMMeta?.alias || [],
    redirect: missionCompleteeCIAsl9ziMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/story/missionComplete.vue").then(m => m.default || m)
  },
  {
    name: playd3xJC3MlT5Meta?.name ?? "story-play",
    path: playd3xJC3MlT5Meta?.path ?? "/story/play",
    meta: playd3xJC3MlT5Meta || {},
    alias: playd3xJC3MlT5Meta?.alias || [],
    redirect: playd3xJC3MlT5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/story/play.vue").then(m => m.default || m)
  },
  {
    name: via1U5ja6vZBpMeta?.name ?? "story-via",
    path: via1U5ja6vZBpMeta?.path ?? "/story/via",
    meta: via1U5ja6vZBpMeta || {},
    alias: via1U5ja6vZBpMeta?.alias || [],
    redirect: via1U5ja6vZBpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/story/via.vue").then(m => m.default || m)
  },
  {
    name: index0ZX8bd93YSMeta?.name ?? "terms-of-service",
    path: index0ZX8bd93YSMeta?.path ?? "/terms-of-service",
    meta: index0ZX8bd93YSMeta || {},
    alias: index0ZX8bd93YSMeta?.alias || [],
    redirect: index0ZX8bd93YSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/terms-of-service/index.vue").then(m => m.default || m)
  },
  {
    name: titlermSEHSSvM1Meta?.name ?? "title",
    path: titlermSEHSSvM1Meta?.path ?? "/title",
    meta: titlermSEHSSvM1Meta || {},
    alias: titlermSEHSSvM1Meta?.alias || [],
    redirect: titlermSEHSSvM1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/title.vue").then(m => m.default || m)
  },
  {
    name: nameEI0CJskJxEMeta?.name ?? "tutorial-name",
    path: nameEI0CJskJxEMeta?.path ?? "/tutorial/name",
    meta: nameEI0CJskJxEMeta || {},
    alias: nameEI0CJskJxEMeta?.alias || [],
    redirect: nameEI0CJskJxEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/tutorial/name.vue").then(m => m.default || m)
  },
  {
    name: prologueHIyEpG54DNMeta?.name ?? "tutorial-prologue",
    path: prologueHIyEpG54DNMeta?.path ?? "/tutorial/prologue",
    meta: prologueHIyEpG54DNMeta || {},
    alias: prologueHIyEpG54DNMeta?.alias || [],
    redirect: prologueHIyEpG54DNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/tutorial/prologue.vue").then(m => m.default || m)
  },
  {
    name: updateBu1ShxyqbmMeta?.name ?? "update",
    path: updateBu1ShxyqbmMeta?.path ?? "/update",
    meta: updateBu1ShxyqbmMeta || {},
    alias: updateBu1ShxyqbmMeta?.alias || [],
    redirect: updateBu1ShxyqbmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/update.vue").then(m => m.default || m)
  },
  {
    name: version_45upq5N0AznaKHMeta?.name ?? "version-up",
    path: version_45upq5N0AznaKHMeta?.path ?? "/version-up",
    meta: version_45upq5N0AznaKHMeta || {},
    alias: version_45upq5N0AznaKHMeta?.alias || [],
    redirect: version_45upq5N0AznaKHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/pages/version-up.vue").then(m => m.default || m)
  },
  {
    name: indexbsCq9v3ztaMeta?.name ?? "_debug",
    path: indexbsCq9v3ztaMeta?.path ?? "/_debug",
    meta: indexbsCq9v3ztaMeta || {},
    alias: indexbsCq9v3ztaMeta?.alias || [],
    redirect: indexbsCq9v3ztaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/node_modules/nuxt-debug/dist/runtime/pages/index.vue").then(m => m.default || m)
  },
  {
    name: cache40aZMkQz6MMeta?.name ?? "_debug-cache",
    path: cache40aZMkQz6MMeta?.path ?? "/_debug/cache",
    meta: cache40aZMkQz6MMeta || {},
    alias: cache40aZMkQz6MMeta?.alias || [],
    redirect: cache40aZMkQz6MMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/node_modules/nuxt-debug/dist/runtime/pages/cache.vue").then(m => m.default || m)
  },
  {
    name: logkf7n8ZflV9Meta?.name ?? "_debug-log",
    path: logkf7n8ZflV9Meta?.path ?? "/_debug/log",
    meta: logkf7n8ZflV9Meta || {},
    alias: logkf7n8ZflV9Meta?.alias || [],
    redirect: logkf7n8ZflV9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/node_modules/nuxt-debug/dist/runtime/pages/log.vue").then(m => m.default || m)
  },
  {
    name: animateu1nJRgqDzeMeta?.name ?? "debugAnimate",
    path: animateu1nJRgqDzeMeta?.path ?? "/debug/animate",
    meta: animateu1nJRgqDzeMeta || {},
    alias: animateu1nJRgqDzeMeta?.alias || [],
    redirect: animateu1nJRgqDzeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/debug/animate.vue").then(m => m.default || m)
  },
  {
    name: audioYlwbnfbbiWMeta?.name ?? "debugAudio",
    path: audioYlwbnfbbiWMeta?.path ?? "/debug/audio",
    meta: audioYlwbnfbbiWMeta || {},
    alias: audioYlwbnfbbiWMeta?.alias || [],
    redirect: audioYlwbnfbbiWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/debug/audio.vue").then(m => m.default || m)
  },
  {
    name: authYfcLfAc5rdMeta?.name ?? "debugAuth",
    path: authYfcLfAc5rdMeta?.path ?? "/debug/auth",
    meta: authYfcLfAc5rdMeta || {},
    alias: authYfcLfAc5rdMeta?.alias || [],
    redirect: authYfcLfAc5rdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/debug/auth.vue").then(m => m.default || m)
  },
  {
    name: debugButtonlshiXBoFIYMeta?.name ?? "debugDebugbutton",
    path: debugButtonlshiXBoFIYMeta?.path ?? "/debug/debugButton",
    meta: debugButtonlshiXBoFIYMeta || {},
    alias: debugButtonlshiXBoFIYMeta?.alias || [],
    redirect: debugButtonlshiXBoFIYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/debug/debugButton.vue").then(m => m.default || m)
  },
  {
    name: directionWUtvwy2odhMeta?.name ?? "debugDirection",
    path: directionWUtvwy2odhMeta?.path ?? "/debug/direction",
    meta: directionWUtvwy2odhMeta || {},
    alias: directionWUtvwy2odhMeta?.alias || [],
    redirect: directionWUtvwy2odhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/debug/direction.vue").then(m => m.default || m)
  },
  {
    name: environmentKV6VGL7GYDMeta?.name ?? "debugEnvironment",
    path: environmentKV6VGL7GYDMeta?.path ?? "/debug/environment",
    meta: environmentKV6VGL7GYDMeta || {},
    alias: environmentKV6VGL7GYDMeta?.alias || [],
    redirect: environmentKV6VGL7GYDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/debug/environment.vue").then(m => m.default || m)
  },
  {
    name: lottiedncoaco4e5Meta?.name ?? "debugLottie",
    path: lottiedncoaco4e5Meta?.path ?? "/debug/lottie",
    meta: lottiedncoaco4e5Meta || {},
    alias: lottiedncoaco4e5Meta?.alias || [],
    redirect: lottiedncoaco4e5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/debug/lottie.vue").then(m => m.default || m)
  },
  {
    name: movieFEcgcdcaUGMeta?.name ?? "debugMovie",
    path: movieFEcgcdcaUGMeta?.path ?? "/debug/movie",
    meta: movieFEcgcdcaUGMeta || {},
    alias: movieFEcgcdcaUGMeta?.alias || [],
    redirect: movieFEcgcdcaUGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/debug/movie.vue").then(m => m.default || m)
  },
  {
    name: playerPrefsga7Xv3L8s4Meta?.name ?? "debugPlayerprefs",
    path: playerPrefsga7Xv3L8s4Meta?.path ?? "/debug/playerPrefs",
    meta: playerPrefsga7Xv3L8s4Meta || {},
    alias: playerPrefsga7Xv3L8s4Meta?.alias || [],
    redirect: playerPrefsga7Xv3L8s4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/debug/playerPrefs.vue").then(m => m.default || m)
  },
  {
    name: pushBPQ4mabeIxMeta?.name ?? "debugPush",
    path: pushBPQ4mabeIxMeta?.path ?? "/debug/push",
    meta: pushBPQ4mabeIxMeta || {},
    alias: pushBPQ4mabeIxMeta?.alias || [],
    redirect: pushBPQ4mabeIxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/debug/push.vue").then(m => m.default || m)
  },
  {
    name: screendUlRQSRWSKMeta?.name ?? "debugScreen",
    path: screendUlRQSRWSKMeta?.path ?? "/debug/screen",
    meta: screendUlRQSRWSKMeta || {},
    alias: screendUlRQSRWSKMeta?.alias || [],
    redirect: screendUlRQSRWSKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/debug/screen.vue").then(m => m.default || m)
  },
  {
    name: server8MIUzJ6VYBMeta?.name ?? "debugServer",
    path: server8MIUzJ6VYBMeta?.path ?? "/debug/server",
    meta: server8MIUzJ6VYBMeta || {},
    alias: server8MIUzJ6VYBMeta?.alias || [],
    redirect: server8MIUzJ6VYBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/debug/server.vue").then(m => m.default || m)
  },
  {
    name: storagevASI2Ah67IMeta?.name ?? "debugStorage",
    path: storagevASI2Ah67IMeta?.path ?? "/debug/storage",
    meta: storagevASI2Ah67IMeta || {},
    alias: storagevASI2Ah67IMeta?.alias || [],
    redirect: storagevASI2Ah67IMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/debug/storage.vue").then(m => m.default || m)
  },
  {
    name: uih8fRcJo6WSMeta?.name ?? "debugUi",
    path: uih8fRcJo6WSMeta?.path ?? "/debug/ui",
    meta: uih8fRcJo6WSMeta || {},
    alias: uih8fRcJo6WSMeta?.alias || [],
    redirect: uih8fRcJo6WSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2043419230/src/src/debug/ui.vue").then(m => m.default || m)
  }
]